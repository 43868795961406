<!--
 * @Description: 商户等级配置
 * @Author: 琢磨先生
 * @Date: 2022-09-28 15:19:16
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-01 16:12:36
-->

<template>
  <!-- <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus"
      >新增床型</el-button
    >
  </el-card> -->
  <el-card class="box">
    <el-table :data="tableData">
      <el-table-column label="等级" width="200">
        <template #default="scope">{{ scope.row.level + 1 }}</template>
      </el-table-column>
      <el-table-column label="房间平台费比例" width="300">
        <template #default="scope"
          ><span v-if="!isEdit">{{ scope.row.decimal_mgr_fee_percent }}%</span>
          <el-row v-if="isEdit">
            <el-col :span="20">
              <el-input
                v-model="scope.row.decimal_mgr_fee_percent"
                placeholder="请输入比例%"
              >
                <template #suffix>%</template>
              </el-input>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column label="商城平台费比例" width="300">
        <template #default="scope"
          ><span v-if="!isEdit">{{ scope.row.decimal_mall_fee_percent }}%</span>
          <el-row v-if="isEdit">
            <el-col :span="20">
              <el-input
                v-model="scope.row.decimal_mall_fee_percent"
                placeholder="请输入比例%"
              >
                <template #suffix>%</template>
              </el-input>
            </el-col>
          </el-row>
        </template>
      </el-table-column>

      <el-table-column label="" >
        <template #header>
          <el-button
            size="small"
            icon="Edit"
            v-if="!isEdit"
            round
            @click="isEdit = true"
            >修改</el-button
          >
          <el-button
            size="small"
            v-if="isEdit"
            :loading="saving"
            type="primary"
            round
            @click="onSubmit"
            >保存</el-button
          >
          <el-button size="small" v-if="isEdit" link round @click="onCancel"
            >取消</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      isEdit: false,
      saving: false,
      tableData: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.$http.get("admin/v1/business/grade").then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
          this.source_list = JSON.parse(JSON.stringify(res.data));
        }
      });
    },

    /**
     * 取消
     */
    onCancel() {
      this.tableData = JSON.parse(JSON.stringify(this.source_list));
      this.isEdit = false;
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.saving = true;
      this.$http
        .post("admin/v1/business/grade/edit", this.tableData)
        .then((res) => {
          if (res.code == 0) {
            this.loadData();
            this.isEdit = false;
          }
        })
        .finally(() => (this.saving = false));
    },
  },
};
</script>

<style  scoped>
</style>